// Colors
$theme-white: #ffffff;
$theme-black: #000000;
$theme-gray: #696969;
$theme-tr-black: #00000080;
$theme-light: #fafafa;
$theme-green: #49ab95;
$theme-dark-green: #3A907C;
$theme-orange: #FCA100;
$theme-dark-orange: #E49200;

// Font
$font-family-heading: "Roboto", sans-serif;
$font-family-body: "Source Sans 3", sans-serif;
$font-size: 18px;
$font-light: 300;
$font-regular: 400;
$font-medium: 500;
$font-bold: 700;

// Transition
$theme-transition: all 400ms, 400ms ease;

// Static sizing
$header-desktop-height: 88px;
$header-height: 65px;