.sr-only {
	border: 0;
	height: 0;
	width: 0;
	margin: 0;
	padding: 0;
	font-size: 0;
	overflow: hidden;
	white-space: nowrap;
	position: relative;
	float: left;
}

.section {
	padding-top: 40px;
	padding-bottom: 40px;

	@include mq($from: lg) {
		padding-top: 60px;
		padding-bottom: 60px;
	}

	.container {
		@include mq($until: lg) {
			padding: 0 30px;
		}

		@include mq($from: xl) {
			max-width: 1160px;
		}
	}
}

@mixin img-ratio() {
	position: absolute;
	inset: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}