// Text media 
.text-media {
	&--dark {
		background-color: $theme-black;

		.heading,
		p {
			color: $theme-white;
		}
	}

	&--gray {
		background-color: #F6F6F6;

		.text-media__asset {
			padding-top: 71%;
		}
	}

	&--right {
		&>.container>.row {
			@include mq($from: lg) {
				flex-direction: row-reverse;
			}
		}
	}

	&--space {
		@include mq($from: lg) {
			padding-top: 140px;
			padding-bottom: 140px;
		}
	}

	&--taller {
		position: relative;

		@include mq($from: lg) {
			padding-top: 115px;
			padding-bottom: 140px;
		}
	}

	&--contact {
		.text-media__content-header {
			@include mq($from: lg) {
				max-width: 440px;
			}
		}
	}

	&__asset {
		position: relative;
		padding-top: 72%;
		width: 100%;

		@include mq($until: lg) {
			.page--home .text-media--taller & {
				position: unset;

				img {
					height: 551px;

					@include mq($until: lg, $and: false, $from: sm) {
						object-fit: contain;
					}
				}
			}
		}

		img {
			@include img-ratio();
		}

		.text-media--taller & {
			padding-top: 551px;

			@include mq($from: lg) {
				padding-top: 129%;

			}
		}

		.text-media--space & {
			padding-top: 63%
		}

		.text-media--contact & {
			@include mq($from: lg) {
				padding-top: 365px;
			}
		}
	}

	&__content {
		@include mq($until: lg) {
			text-align: center;
			margin-top: 20px;
			margin-bottom: 20px;
		}

		p,
		li {
			.sup {
				font-size: 11px;
			}
		}

		&--larger {
			@include mq($until: lg) {
				display: flex;
				flex-direction: column;
				align-items: center;
			}

			@include mq($from: lg) {
				padding-left: 30px;
			}

			p,
			li {
				@include mq($from: lg) {
					font-size: 26px;
					line-height: 43px;
				}
			}
		}
	}

	&__list {
		list-style: disc;
		padding-left: 10px;
		margin-left: 20px;

		li {
			@include mq($until: lg) {
				margin-bottom: 20px;
				text-align: left;
			}

			@include mq($from: lg) {
				line-height: 67px;
			}
		}
	}

	&__icon {
		margin-top: 20px;
		padding-top: 30px;
		padding-bottom: 20px;
		position: relative;

		@include mq($until: lg) {
			padding-bottom: 0;
		}

		&::before {
			position: absolute;
			content: "";
			width: 100%;
			height: 1.5px;
			background-color: #BBBBBB;
			top: 0;
			left: 0;
		}

		&-item {
			align-items: center;

			&:first-of-type {
				margin-top: 20px;
				margin-bottom: 20px;
			}

			@include mq($until: lg) {
				margin-bottom: 20px;

				&.row {
					justify-content: flex-end;
				}

				.col-md-10 {
					padding-left: 0;
				}

				p {
					font-size: 18px;
					text-align: left;
				}
			}
		}

		img {
			height: auto;
			margin-left: auto;
			width: 55px;
			object-fit: contain;
		}
	}

	&__cta {
		margin-top: 40px;

		@include mq($from: lg) {
			margin-top: 30px;
		}
	}

	&>.container>.row {
		align-items: center;

		@include mq($from: lg) {
			margin-left: -25px;
			margin-right: -25px;

			&>.col-12 {
				padding-left: 25px;
				padding-right: 25px;
			}
		}
	}

	.heading {
		margin-bottom: 15px;
	}

	// Page level customization
	// .page--home & {

	&--dark {
		.text-media__asset {
			padding-top: 90%;
		}

		.col-12 {
			&:last-child {
				@include mq($from: lg) {
					padding: 0;
				}
			}

			&:first-child {
				@include mq($from: lg) {
					padding-left: 80px;
				}
			}
		}
	}

	&--right {
		&:last-child {
			.text-media__asset {
				padding-top: 72%;
			}
		}
	}

	&__icon {
		@include mq($until: lg) {
			max-width: 240px;
		}
	}

	// }

	.page--products & {
		&--dark {
			.text-media__content-header {
				max-width: 400px;
			}

			@include mq($until: lg) {
				.col-md-4 {
					order: 2;
				}

				.col-md-8 {
					order: 1;
				}
			}
		}

		&--contact {
			@include mq($from: lg) {
				padding-top: 140px;
				padding-bottom: 140px;
			}
		}
	}

	.page--difference & {
		&--taller {
			@include mq($from: lg) {
				padding-top: 150px;
				padding-bottom: 150px;
			}

			.text-media__asset {
				padding-top: 113%;
			}
		}

		&__icon {
			@include mq($until: lg) {
				margin-top: 0;
			}

			&::before {
				content: none;
			}

			&-item {
				&:not(:last-child) {
					@include mq($from: lg) {
						margin-bottom: 32px;
						margin-top: 0;
					}
				}
			}

			img {
				width: 110px;

				@include mq($until: lg) {
					width: 65px;
					margin: 0 auto;
					margin-bottom: 20px;
				}
			}

			p {
				@include mq($until: lg) {
					text-align: center;
				}
			}

			.heading {
				margin-bottom: 8px;
				text-transform: none;

				@include mq($until: lg) {
					// text-align: left;
				}
			}
		}

		&--gray {
			.text-media__content-header {
				@include mq($from: lg) {
					max-width: 450px;
				}
			}
		}

		&--custom {
			.col-12:last-child {
				@include mq($from: lg) {
					padding-left: 20px;
				}
			}
		}
	}

	.page--about & {
		&--left {
			.text-media__asset {
				img {
					object-fit: contain;
				}
			}
		}
	}
}