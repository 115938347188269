@font-face {
    font-family: 'Roboto';
    src: url('Roboto-Bold.woff2') format('woff2'),
        url('Roboto-Bold.woff') format('woff'),
        url('Roboto-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Sans 3';
    src: url('SourceSans3-Regular.woff2') format('woff2'),
        url('SourceSans3-Regular.woff') format('woff'),
        url('SourceSans3-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

