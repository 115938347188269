// Text Icon
.text-icon {
	background-color: #F6F6F6;

	&__item {
		text-align: center;

		img {
			object-fit: contain;
			width: 80px;
			height: 80px;
			margin: 0 auto;
		}

		.heading {
			margin-top: 15px;
			margin-bottom: 8px;
		}
	}
}