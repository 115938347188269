.error {
	text-align: center;

	&__image {
		max-width: 540px;
		margin: 0 auto;

		img {
			object-fit: contain;
			width: 100%;
			height: auto;
		}
	}

	&__cta {
		margin-top: 20px;
	}
}