.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9999;
	width: 100%;
	background-color: transparent;
	transition: padding 400ms, 400ms ease;

	&.scroll {
		background-color: $theme-white;
	}

	&__wrapper {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 23px 25px;

		@include mq($from: lg) {
			padding: 20px 40px;
			max-width: 1440px;
			margin: 0 auto;
		}
	}

	&__logo-wrapper {
		position: relative;
		width: 72px;
		height: 45px;

		img {
			position: absolute;
			object-fit: contain;
			width: 100%;
			height: auto;
			top: 0;
			left: 0;

			&.black {
				opacity: 0;
			}
		}

	}

	&.scroll,
	.error-page & {
		.white {
			opacity: 0;
		}

		.black {
			opacity: 1;
		}
	}

	&__nav {
		&-item {
			&--cta {
				font-family: $font-family-heading;
				color: $theme-black;
				background-color: $theme-orange;
				text-decoration: none;
				padding: 7px 25px;
				border-radius: 4px;
				z-index: 1;
				transition: background-color 400ms ease;

				&:hover {
					background-color: $theme-dark-orange;
				}
			}
		}
	}
}