// Banner components
.banner {
	position: relative;
	padding-top: $header-height;
	min-height: 700px;
	height: 100vh;

	@include mq($from: lg) {
		min-height: 760px;
		height: 100vh;
		padding-top: $header-desktop-height;
	}

	&__background {
		img {
			@include img-ratio();
		}
	}

	&__overlay {
		display: flex;
		align-items: center;
		height: 100%;

		@include mq($from: lg) {
			padding-left: 105px;
			padding-bottom: 300px;
			padding-top: 105px;
		}

		@include mq($from: 1441px) {
			max-width: 1440px;
			margin: 0 auto;
		}

		&::before {
			content: "";
			position: absolute;
			mix-blend-mode: multiply;
			opacity: 0.3;
			width: 100%;
			height: 100%;
			inset: 0;
			background: $theme-black;

			@include mq($from: lg) {
				background: linear-gradient(90deg, rgba(104, 83, 71, 0.5) 10.12%, rgba(0, 0, 0, 0) 100%);
				max-width: 880px;
				opacity: 1;
			}
		}

	}

	&__content {
		position: relative;
		z-index: 2;

		h1,
		p {
			color: $theme-white;
		}

		@include mq($from: lg) {
			max-width: 780px;

			p {
				font-size: 20px;
				max-width: 80%;
			}
		}

		@include mq($until: lg) {
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			height: 100%;
			width: 100%;
			text-align: center;
			padding: 35px 25px 50px 25px;
		}
	}

	&__cta {
		margin-top: 35px;
	}
}