.product {
	&__heading {
		text-align: center;
		margin-bottom: 48px;
	}

	&__card {
		padding-bottom: 40px;

		&-image {
			position: relative;
			padding-top: 125%;
			width: 100%;
			overflow: hidden;
			border-radius: 5px;

			img {
				@include img-ratio();

				&.hover {
					opacity: 0;
					transition: opacity 0.5s ease-in-out;
				}

				&:hover {
					&.hover {
						opacity: 1;
						transition: opacity 0.5s ease-in-out;
					}
				}
			}
		}

		&-text {
			margin-top: 20px;
		}
	}
}